import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

import { SITE_DOMAIN } from '../constants';
import { DataLayer } from '../types/globals';

export function withDataLayer(func: (dataLayer: DataLayer) => void): void {
  if (process.env.NODE_ENV === 'development') {
    const mockDataLayer = [];
    const origPush = mockDataLayer.push;
    mockDataLayer.push = (...data) => {
      console.log('Pushing data to dataLayer: ', ...data);
      return origPush.call(mockDataLayer, ...data);
    };
    func(mockDataLayer);
  }

  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    func(window.dataLayer);
  }
}

export function useStoreFormFieldsToLocalStorage(fieldsByName: any) {
  if (typeof window !== 'undefined' && window.localStorage) {
    useEffect(
      () => {
        const storedFieldsObj = loadFormFieldsFromLocalStorage();
        const newFieldsObj = {
          ...storedFieldsObj,
          ...Object.fromEntries(
            Object.entries(fieldsByName).map(([fieldName, field]: any) => [fieldName, field.value]),
          ),
        };
        const newFieldValues = Object.entries(newFieldsObj);
        localStorage.setItem(
          'form_fields',
          JSON.stringify(
            newFieldValues
              .map(([fieldName, fieldValue]: any) => {
                let finalValue = fieldValue;
                let type = 'string';
                if (Array.isArray(finalValue)) {
                  finalValue = JSON.stringify(finalValue);
                  type = 'array';
                } else if (finalValue === null || finalValue === undefined) {
                  return finalValue;
                } else if (typeof finalValue !== 'string') {
                  throw new Error('Got unexpected type: ' + typeof finalValue);
                }
                return [fieldName, finalValue, type];
              })
              .filter(Boolean),
          ),
        );
      },
      Object.values(fieldsByName).map((field: any) => field.value),
    );
  }
}

export function loadFormFieldsFromLocalStorage(): any {
  let initialFieldValues: Record<string, string | Array<string>> = {};
  if (typeof window !== 'undefined' && localStorage) {
    const storedFieldsDataArrayStr = localStorage.getItem('form_fields');
    if (storedFieldsDataArrayStr) {
      const storedFieldsDataArray = JSON.parse(storedFieldsDataArrayStr);
      initialFieldValues = Object.fromEntries(
        storedFieldsDataArray.map(([fieldName, value, type]) => {
          let finalValue = value;
          if (type === 'array') {
            finalValue = JSON.parse(finalValue);
          } else if (type !== 'string') {
            throw new Error('Got unexpected type: ' + type);
          }
          return [fieldName, finalValue];
        }),
      );
    }
  }
  return initialFieldValues;
}

export function setCookie(
  name: string,
  value: string,
  {
    expires = 365,
    sameSite = 'none',
    ...extraOptions
  }: {
    expires?: number;
    sameSite?: 'strict' | 'lax' | 'none';
  },
): void {
  const secure = location ? location.protocol === 'https:' : true;

  const cookieOptions = { expires, sameSite, secure, ...extraOptions };

  // Fallback for older browsers where can not set SameSite=None, SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
  if (sameSite === 'none') {
    Cookies.set(name + '-legacy', value, cookieOptions);
  }

  // set the regular cookie
  Cookies.set(name, value, cookieOptions);
}

export function getCookieValue(name: string): string | undefined {
  let cookieValue = Cookies.get(name);

  // if the cookieValue is undefined check for the legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(name + '-legacy');
  }
  return cookieValue;
}

export type StrPartPreprocesser = (str: string) => React.ReactNode;

export function wrapStrPartsBySplitter(
  str: string,
  splitter: string | RegExp,
  wrapStrPart: (strPart: React.ReactNode, index: number) => React.ReactNode,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  const strParts = str.split(splitter).map(strPart => preprocessStrPart(strPart));
  if (strParts.length % 2 === 0) {
    throw new Error(
      `String incorrectly formatted, got even number of parts after split. splitRegex: ${splitter}   str: ${str}`,
    );
  }
  return strParts.map((string, i) => (i % 2 !== 0 ? wrapStrPart(string, i) : string));
}

export function replaceSplitterWithEl(
  str: string,
  splitter: string | RegExp,
  el: React.ReactNode,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  const splitStr = str.split(splitter).map(strPart => preprocessStrPart(strPart));
  const splitStrWithEls: Array<React.ReactNode> = [];
  for (let i = 0; i < splitStr.length; i++) {
    splitStrWithEls.push(<React.Fragment key={i * 2}>{splitStr[i]}</React.Fragment>);
    splitStrWithEls.push(<React.Fragment key={i * 2 + 1}>{el}</React.Fragment>);
  }
  splitStrWithEls.pop();
  return splitStrWithEls;
}

export function replaceNewLinesWithBr(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
): React.ReactNode {
  return replaceSplitterWithEl(str, '\n', <br></br>, preprocessStrPart);
}

export function wrapSquareBracketedWithEm(
  str: string,
  preprocessStrPart: StrPartPreprocesser = str => str,
) {
  return wrapStrPartsBySplitter(str, /[\[\]]/, (el, i) => <em key={i}>{el}</em>, preprocessStrPart);
}

export function checkIsInternalUrl(url: string): boolean {
  if (url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(?:\/|$)`))) {
    return true;
  } else if (url.match(/^\w+:\/\//)) {
    return false;
  } else if (url.match(/^(tel|fax|mailto):/)) {
    return false;
  } else {
    return true;
  }
}

export function getInternalUrlPath(url: string): string {
  if (!checkIsInternalUrl(url)) {
    throw new Error('Called getInternalUrlPath with a non internal url: ' + url);
  }
  if (!url.startsWith('http')) {
    return url;
  }
  const match = url.match(new RegExp(`^https?://(?:www\\.)?${SITE_DOMAIN}(.*)`));
  if (match === null) {
    throw new Error('Got null match from supposedly internal url: ' + url);
  }
  const path = match[1];
  if (path === '') {
    return '/';
  }
  if (!path.startsWith('/')) {
    throw new Error("Url path should start with slash but doesn't: " + url);
  }

  return path;
}

export class CancelablePromise<T> {
  private hasCanceled = false;
  public promise: Promise<T>;

  constructor(promise: Promise<T>, onResolve: (result: T) => void) {
    this.promise = new Promise<T>((resolve, reject) => {
      promise
        .then(result => {
          if (!this.hasCanceled) {
            onResolve(result);
            resolve(result);
          }
        })
        .catch(reject);
    });
  }

  cancel() {
    this.hasCanceled = true;
  }
}

import * as Sentry from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';

import { clsx, wait } from '@digital-spiders/misc-utils';
import { sortBy } from '@digital-spiders/nodash';
import { BsExclamationSquare } from 'react-icons/bs';
import Button from '../components/ui/Button';
import { CancelablePromise } from './utils';

type FieldValue = string | number | boolean | Array<string>;
export type Field =
  | GenericField<string>
  | GenericField<number>
  | GenericField<boolean>
  | GenericField<Array<string>>;
type SubmitState = 'ready' | 'submitting' | 'submitted';
type AsyncValidator<V extends FieldValue> = {
  validation: (value: V) => Promise<{
    result: any;
    error?: string;
  }>;
  onValidationDone?: (value: V, result: any) => void;
  maxValidations?: number;
  optional?: boolean;
};

export type GenericField<T extends FieldValue> = {
  name: string;
  value: T | null;
  setValue: React.Dispatch<React.SetStateAction<T | null>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  validations?: Readonly<
    Array<'required' | 'email' | ((value: T | null) => string | null) | AsyncValidator<T>>
  >;
};

export class FormError extends Error {
  constructor(message: string, readonly formMessage: string, readonly isUserError?: boolean) {
    super(message);
  }
}

export function useFormField<T extends FieldValue>(
  name: string,
  initialValue: T | null,
  validations: Readonly<
    Array<'required' | 'email' | ((value: T | null) => string | null) | AsyncValidator<T>>
  >,
): GenericField<T> {
  const [value, setValue] = useState<T | null>(initialValue);
  const [error, setError] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return {
    name,
    value,
    setValue,
    error,
    setError,
    isFocused,
    setIsFocused,
    validations,
  };
}

export function useForm({
  fieldsByName,
  onSubmit,
  translateFunction = (key, defaultText) => defaultText,
  asyncValidatonsTimeout = 1000,
}: {
  fieldsByName: Record<string, Field>;
  onSubmit: () => boolean | Promise<boolean>;
  translateFunction?: (
    key:
      | 'form.required_field_error'
      | 'form.invalid_email_error'
      | 'form.network_error'
      | 'form.unknown_error'
      | 'form.success_message',
    defaultText: string,
  ) => string;
  asyncValidatonsTimeout?: number;
}): {
  getFieldProps: typeof getFieldProps;
  renderSubmitButton: typeof renderSubmitButton;
  renderFormMessage: typeof renderFormMessage;
  resetState: typeof resetState;
  submitState: SubmitState;
  onFieldUnfocus: typeof onFieldUnfocus;
} {
  const fields = sortBy(
    Object.entries(fieldsByName) as Array<[string, GenericField<FieldValue>]>,
    ([name]) => name,
  ).map(([name, field]) => ({
    ...field,
    name,
  }));

  const [formMessage, setFormMessage] = useState('');
  const [hasFormError, setHasFormError] = useState(false);
  const [submitState, setSubmitState] = useState<SubmitState>('ready');

  const asyncSubmitState = useRef('ready');
  const asyncValidationPromises = useRef<
    Record<
      string,
      {
        promise: CancelablePromise<any>;
        validation: AsyncValidator<FieldValue>;
        state: string;
        currentRun: number;
      }
    >
  >({});
  const asyncTimeoutPromise = useRef<CancelablePromise<void> | null>(null);

  function getFieldErrorMsg<T extends FieldValue>(field: GenericField<T>): string | null {
    const { value, validations } = field;
    if (!validations) {
      return null;
    }
    for (const validation of validations) {
      if (typeof validation === 'function') {
        const errorMessage = validation(value);
        if (errorMessage !== null) {
          return errorMessage;
        }
      } else {
        switch (validation) {
          case 'required':
            if (
              typeof value !== 'number' &&
              (!value || (Array.isArray(value) && value.length === 0))
            ) {
              return translateFunction('form.required_field_error', 'This field is required.');
            }
            break;
          case 'email':
            if (typeof value === 'string' && !value.match(/^\s*[^@\s]+@[^@\s]+\.[^@\s]+\s*$/)) {
              return translateFunction('form.invalid_email_error', 'Invalid email format');
            }
            break;
        }
      }
    }
    return null;
  }

  function getFieldValidationKey(
    field: GenericField<FieldValue>,
    validation: AsyncValidator<FieldValue>,
  ): string {
    const fieldIndex = fields.map(({ name }) => name).indexOf(field.name);
    const validationIndex = field.validations?.indexOf(validation);
    return `${fieldIndex}-${validationIndex}`;
  }

  function onFieldUnfocus<T extends FieldValue>(field: GenericField<T>): void {
    const errorMsg = getFieldErrorMsg(field);
    if (errorMsg !== null) {
      field.setError(errorMsg);
      return;
    }
    // check if we have async validations to run
    if (field.validations) {
      for (const validation of field.validations) {
        if (typeof validation === 'object' && 'validation' in validation && field.value !== null) {
          const value = field.value;
          const cancellablePromise = new CancelablePromise<{
            result: any;
            error?: string;
          }>(validation.validation(value), ({ result }) => {
            if (validation.onValidationDone) {
              validation.onValidationDone(value, result);
            }
          });

          // @ts-expect-error
          const validationKey = getFieldValidationKey(field, validation);
          let currentRun = 1;
          if (validationKey in asyncValidationPromises.current) {
            const previousValidation = asyncValidationPromises.current[validationKey];
            currentRun = previousValidation.currentRun + 1;
            previousValidation.promise.cancel();
            previousValidation.state = 'cancelled';
          }

          if (!validation.maxValidations || currentRun <= validation.maxValidations) {
            asyncValidationPromises.current[validationKey] = {
              promise: cancellablePromise,
              // @ts-expect-error
              validation,
              state: 'running',
              currentRun,
            };

            cancellablePromise.promise
              .then(result => {
                if (result.error) {
                  asyncValidationPromises.current[validationKey].state = 'error';
                  field.setError(result.error);
                } else {
                  asyncValidationPromises.current[validationKey].state = 'done';
                }
              })
              .catch(error => {
                asyncValidationPromises.current[validationKey].state = 'done';
                console.error(error);
                Sentry.captureException(error);
              })
              .finally(() => {
                if (asyncSubmitState.current === 'awaitingAsyncValidations') {
                  internalOnSubmitPostInstantValidations().catch(error => {
                    console.error(error);
                    Sentry.captureException(error);
                  });
                }
              });
          }
        }
      }
    }
  }

  function checkFormHasErrors(): boolean {
    for (const field of fields) {
      if (field.error || getFieldErrorMsg(field) !== null) {
        return true;
      }
    }
    return false;
  }

  async function internalOnSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setFormMessage('');
    setHasFormError(false);
    if (submitState !== 'ready') {
      return;
    }

    if (checkFormHasErrors()) {
      for (const field of fields) {
        if (!field.error) {
          const errorMsg = getFieldErrorMsg(field);
          if (errorMsg !== null) {
            field.setError(errorMsg);
          }
        }
      }
      return;
    }

    setSubmitState('submitting');
    // Add a small delay to allow async validations
    // to start on field blur
    await wait(50);
    asyncTimeoutPromise.current = new CancelablePromise(wait(asyncValidatonsTimeout), () => {
      asyncTimeoutPromise.current = null;
      internalOnSubmitPostInstantValidations().catch(error => {
        console.error(error);
        Sentry.captureException(error);
      });
    });
    asyncSubmitState.current = 'awaitingAsyncValidations';
    await internalOnSubmitPostInstantValidations();
  }

  async function internalOnSubmitPostInstantValidations() {
    // check if all required async validations are done
    for (const promise of Object.values(asyncValidationPromises.current)) {
      if (promise.state === 'running' && !promise.validation.optional) {
        return;
      }
    }

    const hasOptionalAsyncPromisesRunning = Object.values(asyncValidationPromises.current).find(
      promise => promise.state === 'running',
    );

    // if we have optional async promises running
    if (hasOptionalAsyncPromisesRunning) {
      // and timeout is running, wait for them to finish
      if (asyncTimeoutPromise.current) {
        return;
      } else {
        // otherwise cancel the optional async promises
        for (const promise of Object.values(asyncValidationPromises.current)) {
          if (promise.state === 'running') {
            promise.promise.cancel();
            promise.state = 'cancelled';
          }
        }
      }
    } else if (asyncTimeoutPromise.current) {
      // since we don't have optional async promises running, cancel the timeout
      asyncTimeoutPromise.current.cancel();
      asyncTimeoutPromise.current = null;
    }

    asyncSubmitState.current = 'ready';

    const hasErroredAsyncPromises = Object.values(asyncValidationPromises.current).find(
      promise => promise.state === 'error',
    );

    if (hasErroredAsyncPromises) {
      setSubmitState('ready');
      // asyncSubmitState.current = 'ready';
      return;
    }

    // asyncSubmitState.current = 'submitting';
    try {
      const success = await onSubmit();
      if (success) {
        setSubmitState('submitted');
        // asyncSubmitState.current = 'submitted';
        setFormMessage(
          translateFunction(
            'form.success_message',
            "Thank you for your message, we'll contact you shortly.",
          ),
        );
      } else {
        setSubmitState('ready');
        // asyncSubmitState.current = 'ready';
      }
    } catch (error) {
      setSubmitState('ready');
      // asyncSubmitState.current = 'ready';
      setHasFormError(true);
      if (
        error &&
        typeof error === 'object' &&
        (error as { message: string }).message === 'Failed to fetch'
      ) {
        setFormMessage(
          translateFunction('form.network_error', 'Network failed to send your request.'),
        );
      } else if (typeof error === 'object' && 'formMessage' in error) {
        const castError = error as { formMessage?: string; isUserError?: boolean };
        setFormMessage(castError.formMessage || '');
        if (!castError.isUserError) {
          console.error(error);
          Sentry.captureException(error);
        }
      } else {
        setFormMessage(translateFunction('form.unknown_error', 'An unknown error has occurred.'));
        console.error(error);
        Sentry.captureException(error);
      }
    }
  }

  function renderSubmitButton({
    id,
    labels,
    btnClasses,
    iconClasses,
    childrenBefore,
    childrenAfter,
  }: {
    id?: string;
    labels: {
      ready: string;
      submitting: string;
      submitted: string;
    };
    btnClasses?: {
      common?: string;
      ready?: string;
      submitting?: string;
      submitted?: string;
    };
    iconClasses?: {
      common?: string;
      ready?: string;
      submitting?: string;
      submitted?: string;
    };
    childrenBefore?: React.ReactNode;
    childrenAfter?: React.ReactNode;
  }) {
    const btnClass = btnClasses && clsx(btnClasses.common, btnClasses[submitState]);
    const iconClass = iconClasses && clsx(iconClasses.common, iconClasses[submitState]);

    return (
      <Button
        id={id}
        className={btnClass}
        type="submit"
        tabIndex={0}
        onClick={e => {
          internalOnSubmit(e).catch(error => {
            console.error(error);
            Sentry.captureException(error);
          });
        }}
        withoutIconAnimation
      >
        {childrenBefore}
        {iconClass && <i className={iconClass}></i>}
        {labels[submitState]}
        {childrenAfter}
      </Button>
    );
  }

  function renderFormMessage({
    styles,
  }: {
    styles: {
      formMessage?: string;
      formMessageSuccess?: string;
      formMessageError?: string;
    };
  }) {
    if (!formMessage) {
      return null;
    }
    return (
      <div
        className={clsx(
          styles.formMessage,
          formMessage && !hasFormError && styles.formMessageSuccess,
          formMessage && hasFormError && styles.formMessageError,
        )}
      >
        {hasFormError && <BsExclamationSquare />}
        <span>{formMessage}</span>
      </div>
    );
  }

  function getFieldProps<T extends FieldValue>(
    field: GenericField<T>,
    options?: {
      setValuePreprocessor?: (value: T | null) => T | null;
      defaultHelperText?: string;
    },
  ): {
    disabled: boolean;
    value: T | null;
    onChange: (value: T | null) => void;
    onFocus: () => void;
    onBlur: () => void;
    error: boolean;
    helperText: string;
  } {
    return {
      disabled: submitState === 'submitted',
      value: field.value,
      onChange: (value: T | null) => {
        field.setValue(
          options && options.setValuePreprocessor ? options.setValuePreprocessor(value) : value,
        );
        field.setError('');
        // cancel and delete async validations
        for (const validation of field.validations || []) {
          if (typeof validation === 'object' && 'validation' in validation) {
            // @ts-expect-error
            const validationKey = getFieldValidationKey(field, validation);
            if (validationKey in asyncValidationPromises.current) {
              const validationPromise = asyncValidationPromises.current[validationKey];
              if (validationPromise.state === 'running') {
                validationPromise.promise.cancel();
                validationPromise.state = 'cancelled';
              }
            }
          }
        }
        // if field is not focused, run validations
        // since we field is most likely being filled by autofill
        if (!field.isFocused) {
          setTimeout(() => {
            onFieldUnfocus({
              ...field,
              value,
            });
          }, 0);
        }
      },
      onFocus: () => {
        field.setIsFocused(true);
      },
      onBlur: () => {
        field.setIsFocused(false);
        onFieldUnfocus(field);
      },
      error: !!field.error,
      helperText: field.error || (options && options.defaultHelperText) || '',
    };
  }

  function resetState() {
    setFormMessage('');
    setHasFormError(false);
    setSubmitState('ready');
  }

  useEffect(
    () => {
      setFormMessage('');
      setHasFormError(false);
    },
    fields.map(field => field.value),
  );

  return {
    getFieldProps,
    renderSubmitButton,
    renderFormMessage,
    submitState,
    resetState,
    onFieldUnfocus,
  };
}

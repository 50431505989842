import { parseDate } from '@digital-spiders/misc-utils';
import { sortBy } from '@digital-spiders/nodash';
import { graphql } from 'gatsby';
import groq from 'groq';
import React from 'react';
import { ButtonLinkType } from '../graphql-fragments/ButtonLink';
import { SanityImageType } from '../graphql-fragments/SanityImage';
import { LocalizedSEO } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';
import { RawPortableText } from '../types/types';
import ArticleTemplatePage from './ArticleTemplatePage';

export const query = graphql`
  fragment Article on SanityHpWebsiteArticle {
    _id
    title
    slug {
      current
    }
    date
    _createdAt
    image {
      ...SanityImage
      asset {
        url
      }
    }
    excerpt
    category {
      _id
      title
    }
    tags {
      title
    }
    audio {
      asset {
        extension
        url
      }
    }
    _rawContent(resolveReferences: { maxDepth: 4 })
    ctaSection {
      title
      text
      hideTextFieldInsteadOfUsingDefault
      button {
        ...ButtonLink
      }
      hideCtaSection
    }
    seo {
      ...SEO
    }
    unlisted
  }

  query GetArticlePageById($id: String!) {
    sanityHpWebsiteArticle(_id: { eq: $id }) {
      ...Article
    }

    allSanityArticle: allSanityHpWebsiteArticle {
      nodes {
        ...Article
      }
    }

    sanityHpWebsiteArticleSettings(_id: { eq: "hp-website-articleSettings-singleton" }) {
      articleCtaModuleId
      _rawTextAtTheBottomOfTheArticle(resolveReferences: { maxDepth: 4 })
    }
  }
`;

const groqQuery = groq`{
  "sanityHpWebsiteArticle": *[_id == $id][0] {
     ...
  },
  "allSanityArticle": {
    "nodes": *[_type == "hp-website-article"] {
      ...
    }
  }
  "sanityHpWebsiteArticleSettings": *[_id == "hp-website-articleSettings-singleton"][0] {
    ...
  }
}`;

export interface Article {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  date?: string;
  _createdAt: string;
  image: SanityImageType & {
    asset: {
      url: string;
    };
  };
  excerpt?: string;
  category?: {
    _id: string;
    title: string;
  };
  tags?: Array<{
    title: string;
  }>;
  audio?: {
    asset: {
      extension: string;
      url: string;
    };
  };
  _rawContent: RawPortableText;
  ctaSection?: {
    title?: string;
    text?: string;
    hideTextFieldInsteadOfUsingDefault?: boolean;
    hideCtaSection?: boolean;
    button?: ButtonLinkType;
  };
  seo?: LocalizedSEO;
  unlisted?: boolean;
}

interface QueryData {
  sanityHpWebsiteArticle: Article;
  allSanityArticle: {
    nodes: Array<Article>;
  };
  sanityHpWebsiteArticleSettings?: {
    articleCtaModuleId?: string;
    _rawTextAtTheBottomOfTheArticle?: RawPortableText;
  };
}

const ArticlePage = withPagePreview<QueryData>(
  {
    groqQuery,
  },
  ({
    data: {
      allSanityArticle: { nodes: allArticles },
      sanityHpWebsiteArticle: currentArticle,
      sanityHpWebsiteArticleSettings,
    },
  }) => {
    const currentArticleId = currentArticle._id;

    const allArticleExceptCurrentAndUnlisted = allArticles.filter(
      article => !currentArticleId.includes(article._id) && !article.unlisted,
    );

    const articlesWithSameCategorySortedByDate = sortBy(
      allArticleExceptCurrentAndUnlisted.filter(
        article => article.category?._id === currentArticle.category?._id,
      ),
      article => (article.date && parseDate(article.date)) || new Date(article._createdAt),
      'desc',
    );

    const otherArticlesSortedByDate = sortBy(
      allArticleExceptCurrentAndUnlisted.filter(
        article => article.category?._id !== currentArticle.category?._id,
      ),
      article => (article.date && parseDate(article.date)) || new Date(article._createdAt),
      'desc',
    );

    const articlesToShow = [...articlesWithSameCategorySortedByDate, ...otherArticlesSortedByDate];

    return (
      <ArticleTemplatePage
        {...currentArticle}
        otherArticles={articlesToShow}
        articleType="article"
        textAtTheBottomOfTheArticle={
          sanityHpWebsiteArticleSettings?._rawTextAtTheBottomOfTheArticle
        }
        ctaModuleId={sanityHpWebsiteArticleSettings?.articleCtaModuleId}
      />
    );
  },
);

export default ArticlePage;
